@import-sanitize;
@import "bootstrap/dist/css/bootstrap.min.css";
@import "@fontsource/roboto";
@import "@fontsource/roboto/400-italic";
@import "@fontsource/roboto/700";
@import "@fontsource/roboto/700-italic";
@import "@fontsource/noto-sans-kr";
@import "@fontsource/noto-sans-kr/700";
@import "@fontsource/noto-sans-jp";
@import "@fontsource/noto-sans-jp/700";
@import "@fontsource/dongle";
@import "@fontsource/dongle/700";
@import "@fontsource/gamja-flower";
@import "@fontsource/gaegu";

body {
  font-family: "Roboto", "Noto Sans JP", "Noto Sans KR", sans-serif;
}
